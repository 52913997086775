export const educationData = [
    {
        id: 1,
        institution: 'Universidad Nacional de Distancia',
        course: 'Grado en Psicología',
        startYear: '2015',
        endYear: '2020'
    },
    {
        id: 2,
        institution: 'Universidad Nacional de Distancia',
        course: 'Mención "Salud e Intervención en Trastornos Mentales y del Comportamiento',
        startYear: '2015',
        endYear: '2020'
    },

]