import React from 'react'
import { Helmet } from 'react-helmet'


import { Navbar, Footer, Landing, About, Skills, Testimonials, Blog, Education, Experience, Contacts, Projects, Services, Achievement } from '../../components'
import { headerData } from '../../data/headerData'

function Main() {
    return (
        <div>
            <Helmet>
                <title>{headerData.name} | Psicólogía Las Palmas de Gran Canaria </title>
            </Helmet>
            <Navbar />        
            <Landing />
            <About />
            <Education />            
            {/* <Blog /> */}
            <Contacts />
            /<Footer /> 
        </div>
    )
}

export default Main
