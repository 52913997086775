export const socialsData = {
    github: 'https://github.com/fr0ziii',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/sreerag-rajan-55383a222',
    instagram: 'https://www.instagram.com/ang_psicologia/',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: 'https://medium.com/@sreerag.rajan5',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}